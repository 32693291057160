<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">病害控制状态评估</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="BHID"
                    label="病害点"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.BHID"
                        :disabled="!isEdit || $route.query.JCDXID"
                    >
                        <el-option
                            v-for="(item, index) in DiseaseList"
                            :key="`${index}`"
                            :value="item.ID"
                            :label="item.BHMC"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="BHKZZTPG"
                    label="病害控制状态评估"
                    class="from-item-block"
                >
                    <el-select
                        v-model="formdata.dataDic.BHKZZTPG"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in BHKZZTPGList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "bhkzztpg",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "704",
                dataDic: {
                    glycbtid: this.heritageId,
                    BHID: "",
                    BHKZZTPG: "",
                    JCSDXID: null,
                },
            },
            rules: {
                BHID: [
                    {
                        required: true,
                        message: "请选择病害点",
                        trigger: "change",
                    },
                ],
                BHKZZTPG: [
                    {
                        required: true,
                        message: "请选择病害控制状态评估",
                        trigger: "change",
                    },
                ],
            },
            DiseaseList: [],
            BHKZZTPGList: [],
        };
    },
    mounted() {
        this.GetEnum();
        this.getDiseaseList();
        this.formdata.dataDic.BHID = this.$route.query.JCDXID;
    },
    methods: {
        ...mapActions(["GetGLXList", "getAllEnumInfobytableID"]),
        // 获得考古项目列表
        async getDiseaseList() {
            let res = await this.GetGLXList({
                itemcode: "702",
                lm: "jcsj",
            });
            this.DiseaseList = res.ResultValue || [];
        },
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "704",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "BHKZZTPG") {
                    this.BHKZZTPGList = v.dataItems;
                }
            });
        },
    },
};
</script>

<style></style>
